<template>
  <div class="videoSection ">
    <div class="titleBox">
      <p>{{title}}</p>
    </div>
    <div class="navBox">
      <div></div>
      <div @click="goStudy">
        <i class="el-icon-aliai226" style="font-size:40px"></i>
      </div>
      <div @click="back">
        <i class="el-icon-alifanhui" style="font-size:30px"></i>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      // 移动端视屏大小
      bodyWidth: "",
      bodyHeight: "",

      title: "",
      demoId: "",
      demoIndex: "",
      StudentGroupID:"",//分组ID
      projectID: "",
    }
  },
  methods: {
    goStudy() {
      this.$router.push({ name: "studyContent" });
      console.log(this.demoId);
      this.cookies.kkSet("demoId", this.demoId);
      this.cookies.kkSet("demoTitle", this.title);
    },
    back() {
      this.$router.push({ name: "demoIndex" });
    },
    initData() {
      var obj = {
        StudentID: "",
        LearningProjectID: this.projectID,
        StudentGroupID: this.StudentGroupID,
      };
      this.$axios.post('/api/KnowledgePoints/GetList', obj).then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        this.title = data.data[this.demoIndex].Title;
        this.demoId = data.data[this.demoIndex].ID;
        console.log(this.demoId);
      });
    },
    domCss() {
      this.bodyWidth = document.body.clientWidth;
      this.bodyHeight = document.body.clientHeight;
      $(".videoSection ").css({ width: this.bodyHeight });
      $(".videoSection ").css({ height: this.bodyWidth });
      $(".titleBox").css({ height: this.bodyWidth });
      $(".navBox").css({ height: this.bodyWidth });
    }
  },
  mounted() {
    this.demoIndex = this.cookies.kkGet("demoIndex");
    this.studentID = Cookies.get('studentID');
    this.projectID = this.cookies.kkGet("projectID");
    this.StudentGroupID=this.cookies.kkGet("StudentGroupID");
    this.initData();
    this.domCss();
  },
}
</script>

<style>
.videoSection {
  background: #fff;
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
  display: flex;
  justify-content: space-between;
}
.videoSection .titleBox {
  width: calc(100% - 86px);
  display: flex;
  align-items: center;
}
.videoSection .titleBox p {
  width: 100%;
  text-align: center;
  font-family: PingFangSC-regular;
  font-size: 36px;
}
.videoSection .navBox {
  width: 86px;
  background: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}
.videoSection .navBox div {
  color: #fff;
  text-align: center;
}
.videoSection .navBox div i {
  font-size: 22px;
  margin-bottom: 5px;
}
.videoSection .navBox div .el-icon-aliai226 {
  font-size: 30px;
}
</style>